import { useEffect } from 'react';
import { LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_REFRESH_TOKEN_KEY } from '../../customHooks/auth'

export default function App() {
    useEffect(() => {
        window.localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
        window.localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY)
        window.location.replace(window.location.origin)
    }, []);

    return null
}
