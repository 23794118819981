import { useEffect, useRef, useState } from "react"

type PanelProps = {
    icon: string // The rendered Icon to visualize the panel
    client_id: string
    token: string // users access token to inject
    provider: string // who signed the token
}

const panelStyle = (boxId: string) => `
.v-mybje-panel-${boxId}-box-head {}
.mybje-panel svg {
        display: initial;
    }
`

const MyBjePanel = (props: PanelProps) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const panelId = useRef<string>("")
    const panelRef = useRef(null)
    const dropdownRef = useRef(null);

    useEffect(() => {
        const mybje_panel_sdk_src = `${props.provider}/api/panel/js`;
        const id = 'mybje_panel_sdk_src';
        if (document.getElementById(id)) return;

        const script = document.createElement('script');
        script.id = id;
        script.src = mybje_panel_sdk_src;

        document.getElementsByTagName('head')[0].appendChild(script);
        document.addEventListener('mousedown', handleClickOutside);

    }, [props.provider, isDropdownOpen])

    useEffect(() => {
        if (panelId.current == "") {
            return
        }

        const styleEle = document.createElement('style')
        styleEle.innerText = panelStyle(panelId.current)
        document.getElementsByTagName('head')[0].appendChild(styleEle);

    }, [panelId.current])

    const openDropdown = () => {
        if (panelRef.current) {
            const panel: HTMLElement = panelRef.current
            const panelBox: HTMLDivElement = panel.getElementsByClassName('c-mybje-panel-box')[0] as HTMLDivElement
            panelId.current = panel.firstElementChild?.firstElementChild?.className.replace('v-mybje-panel-', '') as string
            panelBox.style.display = 'block'
        }

        setDropdownOpen(true);
    }

    const closeDropdown = () => {
        if (panelRef.current) {
            const panelBox: HTMLDivElement = (panelRef.current as HTMLDivElement).getElementsByClassName('c-mybje-panel-box')[0] as HTMLDivElement
            panelBox.style.display = 'none'
        }
        setDropdownOpen(false);
    }

    const handleClickOutside = (event: Event) => {
        if (dropdownRef.current && !(dropdownRef.current as HTMLElement).contains((event.target as Node))) {
            closeDropdown();
        }
    };

    return (
        <div>
            <img className="content-center w-full p-4 md:float-right h-14 min-w-[60px]" src={props.icon} onClick={openDropdown} />
            <div ref={dropdownRef} className={`myBjeDropdown ${isDropdownOpen ? "" : "hidden"}`}>
                <ul className="p-0">
                    <div className="mybje-panel"
                        ref={panelRef}
                        data-mybje-panel-client-id={props.client_id}
                        data-mybje-panel-access-token={props.token}
                        data-mybje-panel-template-version="2"
                        data-mybje-panel-locale="de"
                        data-mybje-panel-logout-href="/logout"
                    />
                </ul>
            </div >
        </div >
    );
}

export default MyBjePanel
