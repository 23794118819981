import { useTranslation } from 'react-i18next'
import { useNavigate } from '@tanstack/react-location'
import { useAtomValue } from 'jotai'

import ErrorBoundary from '../../components/ErrorBoundary'
import QCard from '../../components/QCard'
import { userAtom } from '../../components/Jotai'

import bulb from '../../../assets/img/ABB_picto_48x48_14_solution_0530.svg'
import addDate from '../../../assets/img/ABB_picto_48x48_09_calendar_add_date_0395.svg'
import calender from '../../../assets/img/ABB_picto_48x48_09_calendar_0396.svg'
import calendars from '../../../assets/img/ABB_picto_48x48_09_calendars_0397.svg'
import rights from '../../../assets/img/ABB_picto_48x48_10_person_cycle_0447.svg'
import info from '../../../assets/icons/info.svg'
import background from '../../../assets/img/re-design/smarter-gallery-header-full.jpg'
import { isAdm, isQ } from '../../customHooks/auth'

export default function App() {

    const { t } = useTranslation(['home'])
    const navigate = useNavigate()
    const user = useAtomValue(userAtom)

    const goToBooking = () => {
        navigate({ to: '/booking' })
    }

    const goToOwnAppointment = () => {
        navigate({ to: '/ownAppointment' })
    }

    const goToUseFullInformation = () => {
        navigate({ to: '/useFullInformation' })
    }

    const goToBookingCalendar = () => {
        navigate({ to: '/bookingCalendar' })
    }

    const goToRights = () => {
        navigate({ to: '/rights' })
    }

    return (
        <ErrorBoundary>
            <div className="grid grid-cols-1">
                <div className="relative flex justify-center mb-4 xl:mb-0 overflow-y-clip md:overflow-visible">
                    <img
                        className="mx-auto xl:mx-0 h-[380px] md:h-[703px] w-full"
                        src={background}
                        alt="background"
                    />
                    <div className="absolute top-1/4 md:top-1/2 w-4/5">
                        <div className="relative z-50 grid grid-cols-1 gap-5">
                            <h3 className="text-white text-base text-light font-[BJEAvertaLight] mb-[-1.2rem]">{t('subtitle')}</h3>
                            <h1 className="text-white md:text-7xl font-[BJEAvertaBold]">
                                {t('title')}
                            </h1>
                            <span className="text-white text-4xl font-[BJEAvertaBold]">BUSCH-JAEGER {t('experience live')}</span>
                        </div>
                        <div className="relative top-[-340px] left-[-78px] bg-bje-primary w-44 h-[560px] z-0 opacity-[0.8]">&nbsp;</div>
                    </div>
                </div>
                <div className="z-50 mb-28 mt-8 md:mt-[-36px] grid content-center justify-center flex-1">
                    <div
                        className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-items-center`}
                    >
                        <div className="relative md:row-span-2 md:col-span-2 card flex-row relative rounded-lg bg-bje-light border-2 border-bje-light px-8 md:max-w-[42rem] mx-2 md:mx-0">
                            <span className="absolute right-8 top-0 mt-[-2px] g-primary bg-primary h-1 w-[34px]">&nbsp;</span>
                            <div className="card-body py-4">
                                <div className='self-end'>
                                    <img className="w-[32px]" src={info} alt={`icon_info`} />
                                </div>
                                <h2 className="card-title">
                                    {t('specialist on site title')}
                                </h2>
                                <p>
                                    <strong>{t('specialist on site text1')}</strong> {t('specialist on site text2')}
                                </p> 
                            </div>
                        </div>
                        <QCard
                            onClick={goToBooking}
                            icon={addDate}
                            text={t('book an appointment')}
                        />
                        <QCard
                            onClick={goToUseFullInformation}
                            icon={bulb}
                            text={t('useful information')}
                        />
                        <QCard
                            onClick={goToOwnAppointment}
                            icon={calender}
                            text={t('my dates')}
                        />
                        {isAdm(user?.email) || isQ(user?.email) && (
                            <QCard
                                onClick={goToBookingCalendar}
                                icon={calendars}
                                text={t('booking calendar')}
                            />
                        )}
                        {user?.rights?.includes('setRights') && (
                            <QCard
                                onClick={goToRights}
                                icon={rights}
                                text={t('right management')}
                            />
                        )}
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    )
}
