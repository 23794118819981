// https://blog.logrocket.com/complete-guide-authentication-with-react-router-v6/
// Toby wagt dann auch mal einen Verusch.
// Schlimmer kanns eh nicht werden :
/******************************************************************************
 ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣀⣠⣤⣶⠶⠶⠟⠻⠛⠻⠿⠿⠿⠿⠿⢛⠛⠒⠓⠒⠶⣄⡀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣠⡴⠞⠛⠓⣢⣴⣆⠒⡒⠀⠂⠀⠀⡄⠀⠀⠰⢦⣌⣁⠢⠀⠀⠈⠻⣆⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⡟⠀⠀⠠⠊⠁⠀⠀⠹⡅⠀⠀⠀⠐⠃⠀⣀⣀⣀⠀⠑⠩⠢⡀⠀⠀⠙⣇⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣠⡾⢁⣀⡀⠠⣴⣶⣶⣦⣤⣅⡀⠀⠀⣠⠾⠛⢛⣿⣿⣿⣷⣦⠠⣀⠀⢀⣀⣨⣧⣄⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣼⣏⢻⣿⣭⣅⡀⠀⡍⠉⠉⢙⣿⠋⠀⠀⠘⠛⠛⠉⠉⢿⣭⣉⣀⣠⡴⢾⠷⠻⣦⡘⢿⢧⡀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢿⣿⢡⠈⢁⣌⠙⠛⠃⠀⣠⡾⠋⠀⠀⠀⢠⣤⣄⠀⣀⡀⠈⠉⢉⣠⡴⠿⣧⣀⢈⡇⢸⢸⡇
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⢿⡲⠥⣼⣿⣄⠀⠐⠛⠿⣦⣀⣀⠰⠷⠶⢺⡟⣀⣀⣤⡴⠾⡿⠁⣀⣴⡏⠉⢹⢇⡎⣼⠃
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠸⡇⠀⣿⣏⡟⠛⣶⠤⠤⢤⣽⣧⣴⡶⠗⠛⠿⣯⣁⣀⣠⣾⣿⠛⣹⠏⠀⠀⠀⣿⡾⠁⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡿⠀⣿⣿⣿⣾⣷⣤⣤⣾⣦⣤⣼⣧⣤⣤⡶⣿⡟⠛⠉⠉⣿⡾⠃⠀⠀⠀⣴⠏⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣿⠀⢸⣿⣿⣿⣿⣿⠿⣿⡿⠿⠿⢿⠛⠀⠀⠸⣇⠀⣀⡿⠟⠀⠀⠀⢀⡼⠇⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢠⡟⠀⠈⠻⠧⣿⣦⣽⣄⣈⣧⣀⣀⣼⣄⣀⣤⠴⠟⠛⣉⠀⣀⠤⣦⡶⠏⠁⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣤⣴⣴⣶⣶⣶⣶⣶⣿⣿⡇⠀⡄⠐⠤⣀⡀⠉⠉⠉⠉⠋⠉⠉⢀⣠⣤⣔⠾⠿⣊⣽⡾⠛⠉⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠻⢿⣿⣯⣌⣈⠀⠀⠸⣇⠀⠈⠢⠤⠤⠬⠥⠤⠤⠄⠀⠀⠒⠉⠉⣀⣤⡶⠟⠋⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠙⢿⣿⣿⣷⡄⠙⢷⣤⣀⠀⠀⠀⢀⣀⣀⣤⣤⠶⢶⣶⣿⣏⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠙⢿⠟⠁⠀⠀⠈⠉⠉⠉⠉⠉⠉⠉⠀⠀⠀⠸⣿⣿⣿⡆⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢠⣴⣦⣄⠀⠀⠀⢻⣿⣿⣿⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢿⣿⣿⣿⠀⠀⠀⠸⣿⡿⣿⣧⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠙⢿⣧⣄⠀⠀⠀⢻⣇⢻⣿⡄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠹⢿⣷⣄⠀⣼⣿⠈⣿⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠛⣿⣷⣾⡟⠀⢸⣿⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢙⣿⣿⣦⡀⣿⡆⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣸⣿⠛⠻⣿⣿⣧⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣿⣷⣶⣤⣀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣿⡟⠀⠀⠀⠉⠉⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢿⣿⠛⠻⢿⣿⣶⣦⣄⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣸⣿⠃⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠸⣿⡇⠀⠀⠈⠉⠛⠿⢿⣷⣶⣤⣄⡀⠀⠀⠀⠀⢀⣿⡿⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣿⣷⠀⠀⠀⠀⠀⠀⠀⠈⠙⠻⢿⣿⣷⣦⣄⡀⣼⣿⠇⠀⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢠⣶⣿⣿⣿⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠙⠻⣿⣿⣿⡟⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠉⠉⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠐⠀⠈⢹⣿⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠠⠀⠀⠁⠀⠀⠀⠀⠀⠀⠘⣿⣇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡀⠠⠀⠂⠀⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢿⣿⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠄⠰⠊⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⢀⠀⡀⠀⠀⡀⠀⢸⣿⡇⠐⠀⠂⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠐⠒⠢⠖⢍⢉⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⣿⣷⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠁⠐⠀⡀⠀⠀⠀⠀⠀⣿⣿⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠐⢀⠀⠀⠀⣿⣿⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠂⢰⣿⣿⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣼⣿⡏⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣿⣿⠃⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣸⣿⡿⠀⠈⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣿⣿⠃⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⣿⣿⣶⣶⣶⣤⡄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠉⠛⠛⠛⠛⠛⠋⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
******************************************************************************/

import { User } from '../types/index'
import { userAtom } from '../components/Jotai'

import React, { ReactNode, useMemo } from 'react'
import { atom, useAtom } from "jotai"
import { atomWithStorage } from 'jotai/utils'

interface Props {
    children: ReactNode
}

export interface IAuthProvider {
    authorization: User,
    login: () => void,
    logout: () => void
}

export const AuthContext = React.createContext<User>(userAtom.read(() => userAtom))

export const LOCAL_STORAGE_TOKEN_KEY = "access_token"
export const LOCAL_STORAGE_REFRESH_TOKEN_KEY = "refresh_token"


// use with refresh and access token
export const accessTokenAtom = atomWithStorage(LOCAL_STORAGE_TOKEN_KEY, localStorage.getItem("access_token") || "")
export const refreshTokenAtom = atomWithStorage(LOCAL_STORAGE_REFRESH_TOKEN_KEY, localStorage.getItem("refresh_token") || "")

export const isAdm = (email: string): boolean => {
	return email.includes("@de.abb.com") || email.includes("@pohlmann-services.de")
}

export const isQ = (email: string): boolean => {
	return email.includes("@qmarketing.de")
}

let maxRetries = 3
let refreshingTokens = false

export const AuthProvider: React.FC<Props> = ({ children }) => {

    const [user, setUser] = useAtom(
        useMemo(
            () => atom(
                (get) => get(userAtom),
                (_, set, update: User) => set(userAtom, update)
            ),
            [userAtom])
    )

    if (process.env.SSO_PROVIDER === undefined) {
        return <AuthContext.Provider value={user} />
    }

    const [token, setToken] = useAtom(accessTokenAtom)
    const [refreshToken, setRefreshToken] = useAtom(refreshTokenAtom)

    const login = () => {
        // hotfix endless auth 
        if (!maxRetries--) {
            setToken("failure")
            return
        }

        const uri = new URL(document.location.toString())
        if (uri.searchParams.has("code")) {

            // exchange the code with an active token
            const code = uri.searchParams.get("code")

            const tokenRequest = new XMLHttpRequest()

            const requestBody = new URLSearchParams({
                'grant_type': 'authorization_code',
                'code': code as string,
                'client_id': `${process.env.SSO_CLIENT_ID}`,
                'client_secret': `${process.env.SSO_CLIENT_SECRET}`
            })

            tokenRequest.onreadystatechange = function() {
                if (this.readyState == 4) {
                    if (this.status == 200) {
                        const data = JSON.parse(this.responseText)
                        setToken(data.access_token)
                        setRefreshToken(data.refresh_token)
                        document.location.search = ""
                    } else {
                        console.error("failed to authorize")
                        return
                    }
                }
            };

            tokenRequest.onerror = (e) => {
                console.error(e)
            };

            tokenRequest.open('POST', `${process.env.SSO_PROVIDER}/sso/token`, true)
            tokenRequest.send(requestBody)

            return
        }

        if (token == "refresh") {
            refreshAccessToken(refreshToken)
            return
        }

        // Initialize SSO code flow
        document.location = `${process.env.SSO_PROVIDER}${process.env.AUTH_URI}?response_type=code&client_id=${process.env.SSO_CLIENT_ID}`
    }

    const fetchUser = () => {
        const userRequest = new XMLHttpRequest()

        userRequest.onreadystatechange = function() {
            if (this.readyState == 4) {
                if (this.status == 200) {
                    const userData = JSON.parse(this.responseText)
                    setUser(userData.user)
                } else if (this.status >= 400) {
                    if (this.status === 401) {
                        return refreshAccessToken(refreshToken)
                    }
                    console.error("failed query user ", this.status)
                }
            }
        };

        userRequest.onerror = (e) => {
            console.error(e)
            return
        };

        userRequest.open('GET', `${process.env.BACKEND}/getUser`, true)
        userRequest.setRequestHeader("Authorization", `Bearer ${token}`)
        userRequest.send()

        return
    }

    const refreshAccessToken = (withToken: string) => {

        if (refreshingTokens) {
            return {
                access: token,
                refresh: refreshToken
            }
        }

        refreshingTokens = true
        // hotfix endless auth 
        if (!maxRetries--) {
            return "failure"
        }

        const tokenRequest = new XMLHttpRequest()

        const requestBody = new URLSearchParams({
            'grant_type': 'refresh_token',
            'refresh_token': withToken,
            'client_id': `${process.env.SSO_CLIENT_ID}`,
            'client_secret': `${process.env.SSO_CLIENT_SECRET}`
        })

        const newTokens = {
            access: "",
            refresh: ""
        }

        tokenRequest.onreadystatechange = () => {
            if (tokenRequest.readyState == 4) {
                if (tokenRequest.status == 200) {
                    const data = JSON.parse(tokenRequest.responseText)
                    setToken(data.access_token)
                    setRefreshToken(data.refresh_token)
                    console.info("refreshed tokens")
                } else {
                    console.error("failed to refresh access token", tokenRequest.responseText)
                    // clear tokens, logs user out
                    localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
                    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY)

                    if (navigator.userAgent.toLowerCase().indexOf('safari/') === -1) {
                        window.location.reload()
                    }
                    return
                }
            }
        };

        tokenRequest.onerror = (e) => {
            console.error(e)
        };

        tokenRequest.open('POST', `${process.env.SSO_PROVIDER}/sso/token`, true)
        tokenRequest.send(requestBody)


        return newTokens
    }

    // To Be USED somehow somehwere, sometime
    /**
    const logout = () => {
        //   setUser({} as User)
        setToken("")
    }
**/

    // lets store the token to persist auth between reloads
    if (token == "") {
        login()
        return <AuthContext.Provider value={user}></AuthContext.Provider >
    }

    if (token != "" && user.email == "") {
        fetchUser()
    }

    if (token == "refresh") {
        refreshAccessToken(refreshToken)
        return <AuthContext.Provider value={user}>{children}</AuthContext.Provider >
    }

    if (token !== "" && token !== "refresh") {
        const tokenJSON = JSON.parse(atob(token.split('.')[1]))
        const currentTime = Math.floor(new Date() as any / 1000)
        if ((currentTime - tokenJSON.exp) >= 0) {
            refreshAccessToken(refreshToken)
        }
    }

    return <AuthContext.Provider value={user}>{children}</AuthContext.Provider >
}

export const useAuth = () => {
    return React.useContext(AuthContext)
}
